import './App.css';
import SpeechToTextComponent from './SpeechToTextComponent';


function App() {
  return (
    <div className="App">
      <SpeechToTextComponent />
    </div>
  );
}

export default App;