import React, { useState, useEffect, useRef, useCallback } from 'react';
import colorLogo from './assets/color.png';
import * as sdk from 'microsoft-cognitiveservices-speech-sdk';
import * as microsoftTeams from "@microsoft/teams-js";
//import axios from 'axios';

const SPEECH_KEY = '9qBhM45nuEmfzTDDTyJFSl0j7e8f317z9z3BUxTrbhD1qL4H8dFMJQQJ99AJAC5RqLJXJ3w3AAAYACOGfJHi';
const SPEECH_REGION = 'westeurope';
const TRANSLATOR_KEY = 'AAzgUJTaz9kanUktd6RBCeLugLFAwcV450bH2Ho2xsq4potwPzhaJQQJ99AJAC5RqLJXJ3w3AAAbACOG4sRG';
const TRANSLATOR_REGION = 'westeurope';
const API_BASE_URL = 'https://cscvoiceapi.azurewebsites.net';
const DISPLAY_DURATION = 1800000; // Her konuşmanın ekranda kalma süresi (ms)
//const AZURE_OPENAI_KEY= '868d05a9bd05447ca9d3b25bcff1ab86';
//const AZURE_OPENAI_ENDPOINT= 'https://auraopenaitest.openai.azure.com';
//const AZURE_OPENAI_DEPLOYMENT='gpt-4o-2024-08-06';

//const DEFAULT_MEETING_ID = 'default-meeting';
const DEFAULT_USER_NAME = 'Misafir Kullanıci';

const SUPPORTED_LANGUAGES = [
  { 
    code: 'tr-TR', 
    name: 'Türkçe', 
    shortCode: 'tr',
    icon: '🇹🇷',
    phraseList: ['merhaba', 'günaydın', 'iyi akşamlar']
  },
  { 
    code: 'en-US', 
    name: 'İngilizce', 
    shortCode: 'en',
    icon: '🇺🇸',
    phraseList: ['hello', 'good morning', 'good evening']
  },
  { 
    code: 'ru-RU', 
    name: 'Rusça', 
    shortCode: 'ru',
    icon: '🇷🇺',
    phraseList: ['привет', 'доброе утро', 'добрый вечер']
  },
  { 
    code: 'es-ES', 
    name: 'İspanyolca', 
    shortCode: 'es',
    icon: '🇪🇸',
    phraseList: ['hola', 'buenos días', 'buenas noches']
  },
  { 
    code: 'it-IT', 
    name: 'İtalyanca', 
    shortCode: 'it',
    icon: '🇮🇹',
    phraseList: ['ciao', 'buongiorno', 'buonasera']
  },
  { 
    code: 'de-DE', 
    name: 'Almanca', 
    shortCode: 'de',
    icon: '🇩🇪',
    phraseList: ['hallo', 'guten Morgen', 'guten Abend']
  },
  { 
    code: 'pt-PT', 
    name: 'Portekizce', 
    shortCode: 'pt',
    icon: '🇵🇹',
    phraseList: ['olá', 'bom dia', 'boa noite']
  },
  { 
    code: 'zh-CN', 
    name: 'Çince', 
    shortCode: 'zh',
    icon: '🇨🇳',
    phraseList: ['你好', '早上好', '晚上好']
  },
  { 
    code: 'he-IL', 
    name: 'İbranice', 
    shortCode: 'he',
    icon: '🇮🇱',
    phraseList: ['שלום', 'בוקר טוב', 'ערב טוב']
  },
  { 
    code: 'fr-FR', 
    name: 'Fransızca', 
    shortCode: 'fr',
    icon: '🇫🇷',
    phraseList: ['bonjour', 'bonsoir', 'bonne nuit']
  },
  { 
    code: 'el-GR', 
    name: 'Yunanca', 
    shortCode: 'el',
    icon: '🇬🇷',
    phraseList: ['γειά', 'καλημέρα', 'καλησπέρα']
  },
  { 
    code: 'ar-SA', 
    name: 'Arapça', 
    shortCode: 'ar',
    icon: '🇸🇦',
    phraseList: ['مرحباً', 'صباح الخير', 'مساء الخير']
  },
  { 
    code: 'bg-BG', 
    name: 'Bulgarca', 
    shortCode: 'bg',
    icon: '🇧🇬',
    phraseList: ['здравейте', 'добро утро', 'добър вечер']
  },
  { 
    code: 'cs-CZ', 
    name: 'Çekçe', 
    shortCode: 'cs',
    icon: '🇨🇿',
    phraseList: ['ahoj', 'dobré ráno', 'dobrý večer']
  },
  { 
    code: 'da-DK', 
    name: 'Danca', 
    shortCode: 'da',
    icon: '🇩🇰',
    phraseList: ['hej', 'godmorgen', 'god aften']
  },
  { 
    code: 'fi-FI', 
    name: 'Fince', 
    shortCode: 'fi',
    icon: '🇫🇮',
    phraseList: ['hei', 'hyvää huomenta', 'hyvää iltaa']
  },
  { 
    code: 'hu-HU', 
    name: 'Macarca', 
    shortCode: 'hu',
    icon: '🇭🇺',
    phraseList: ['helló', 'jó reggelt', 'jó estét']
  },
  { 
    code: 'ja-JP', 
    name: 'Japonca', 
    shortCode: 'ja',
    icon: '🇯🇵',
    phraseList: ['こんにちは', 'おはよう', 'こんばんは']
  },
  { 
    code: 'ko-KR', 
    name: 'Korece', 
    shortCode: 'ko',
    icon: '🇰🇷',
    phraseList: ['안녕하세요', '좋은 아침', '좋은 저녁']
  },
  { 
    code: 'pl-PL', 
    name: 'Lehçe', 
    shortCode: 'pl',
    icon: '🇵🇱',
    phraseList: ['cześć', 'dzień dobry', 'dobry wieczór']
  },
  { 
    code: 'ro-RO', 
    name: 'Rumence', 
    shortCode: 'ro',
    icon: '🇷🇴',
    phraseList: ['bună', 'bună dimineața', 'bună seara']
  },
  { 
    code: 'sv-SE', 
    name: 'İsveççe', 
    shortCode: 'sv',
    icon: '🇸🇪',
    phraseList: ['hej', 'god morgon', 'god kväll']
  },
  { 
    code: 'uk-UA', 
    name: 'Ukraynaca', 
    shortCode: 'uk',
    icon: '🇺🇦',
    phraseList: ['привіт', 'добрий ранок', 'добрий вечір']
  },
  { 
    code: 'vi-VN', 
    name: 'Vietnamca', 
    shortCode: 'vi',
    icon: '🇻🇳',
    phraseList: ['xin chào', 'chào buổi sáng', 'chào buổi tối']
  },
  { 
    code: 'th-TH', 
    name: 'Taylandca', 
    shortCode: 'th',
    icon: '🇹🇭',
    phraseList: ['สวัสดี', 'สวัสดีตอนเช้า', 'สวัสดีตอนเย็น']
  },
  { 
    code: 'ms-MY', 
    name: 'Malayca', 
    shortCode: 'ms',
    icon: '🇲🇾',
    phraseList: ['halo', 'selamat pagi', 'selamat petang']
  }
];

// API çağrı yardımcı fonksiyonu
const apiCall = async (endpoint, options = {}) => {
  try {
    console.log(`API çağrısı yapılıyor: ${endpoint}`, options);

    const response = await fetch(`${API_BASE_URL}${endpoint}`, {
      ...options,
      headers: {
        'Content-Type': 'application/json; charset=UTF-8',
        'Accept': 'application/json; charset=UTF-8',
        'Accept-Charset': 'UTF-8',
        ...options.headers
      }
    });

    const data = await response.json();
    
    if (!response.ok) {
      throw new Error(data.error || `HTTP error! status: ${response.status}`);
    }

    if (data.error) {
      throw new Error(data.error);
    }

    console.log(`API yanıtı alındı: ${endpoint}`, data);
    return data;
  } catch (error) {
    console.error(`API Hatası (${endpoint}):`, error);
    throw error;
  }
};
export function SpeechToTextComponent() {
  // State tanımlamaları
  const [isListening, setIsListening] = useState(false);
  const [error, setError] = useState('');
  const [micPermission, setMicPermission] = useState(false);
  const [selectedLanguage, setSelectedLanguage] = useState('tr-TR');
  const [userName, setUserName] = useState(DEFAULT_USER_NAME);
  const [userInfo, setUserInfo] = useState(null);
  const [meetingInfo, setMeetingInfo] = useState(null);
  const [isMeetingActive, setIsMeetingActive] = useState(true);
  const [isTeamsClient, setIsTeamsClient] = useState(false);
  const [isInitializing, setIsInitializing] = useState(true);
  const [speechServiceReady, setSpeechServiceReady] = useState(false);
  const [displayedMessages, setDisplayedMessages] = useState([]);
  const [lastFetchedMessageId, setLastFetchedMessageId] = useState(0);
  const [buttonsDisabled, setButtonsDisabled] = useState(false);

  // Refs
  const recognizers = useRef({});
  const isProcessing = useRef(false);
  const isTeamsInitialized = useRef(false);
  const isTeamsMeetingInitialized = useRef(true);
  const messageQueueRef = useRef([]);
  const displayTimeoutRef = useRef(null);
  const currentMeetingDataRef = useRef(null);
  const isMeetingActiveRef = useRef(true);

  // Teams kontrolü
  const checkTeamsContext = useCallback(async () => {
    try {
      console.log('Teams context kontrolü başlatılıyor...');
      await microsoftTeams.app.initialize();
      const context = await microsoftTeams.app.getContext();
      
      if (context) {
        console.log('Teams context bulundu:', context);
        setIsTeamsClient(true);
        return true;
      }
      return false;
    } catch (err) {
      console.log('Teams context bulunamadı:', err);
      setIsTeamsClient(false);
      return false;
    }
  }, []);

  // Speech servis kontrolü
  const checkSpeechService = useCallback(async () => {
    try {
      console.log('Speech servis kontrolü başlatılıyor...');
      const speechConfig = sdk.SpeechConfig.fromSubscription(SPEECH_KEY, SPEECH_REGION);
      if (speechConfig) {
        console.log('Speech servis hazır');
        setSpeechServiceReady(true);
        return true;
      }
      return false;
    } catch (err) {
      console.error('Speech servis hatası:', err);
      setSpeechServiceReady(false);
      return false;
    }
  }, []);

  // Çeviri fonksiyonu
  const translateText = useCallback(async (text, fromLang, toLang) => {
    if (!text?.trim()) return '';
    try {
      console.log('Çeviri başlatılıyor:', { text, fromLang, toLang });
      const response = await fetch(
        `https://${TRANSLATOR_REGION}.api.cognitive.microsoft.com/translator/text/v3.0/translate?api-version=3.0&from=${fromLang}&to=${toLang}`,
        {
          method: 'POST',
          headers: {
            'Ocp-Apim-Subscription-Key': TRANSLATOR_KEY,
            'Content-Type': 'application/json',
            'Ocp-Apim-Subscription-Region': TRANSLATOR_REGION
          },
          body: JSON.stringify([{ Text: text }])
        }
      );

      const data = await response.json();
      console.log('Çeviri tamamlandı:', data);
      return data[0]?.translations[0]?.text || 'Çeviri başarısız';
    } catch (error) {
      console.warn('Çeviri hatası:', error);
      return 'Çeviri başarısız';
    }
  }, []);

  // Dil değişikliği handler'ı
  const handleLanguageChange = async (e) => {
    if (isListening) return;
    
    const newLanguage = e.target.value;
    console.log('Dil değiştiriliyor:', { newLanguage, meetingInfo, userInfo });
  
    try {
      if (meetingInfo?.id && userInfo?.id) {
        // Kullanıcı tercihini güncelle
        await apiCall('/api/user-preferences', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json'
          },
          body: JSON.stringify({
            meetingId: meetingInfo.id,
            userId: userInfo.id,
            languageId: newLanguage
          })
        });
  
        // State'i güncelle
        setSelectedLanguage(newLanguage);
        
        // Mesaj listesini yenile
        setLastFetchedMessageId(0);
        setDisplayedMessages([]);
        messageQueueRef.current = [];
        await fetchNewMessages();
  
        console.log('Dil tercihi güncellendi:', newLanguage);
      } else {
        console.error('Meeting veya user bilgisi eksik:', { meetingInfo, userInfo });
      }
    } catch (error) {
      console.error('Dil değiştirme hatası:', error);
      setError('Dil tercihi kaydedilemedi: ' + error.message);
    }
  };

  // Mikrofon kontrolü
  const checkMicrophonePermission = useCallback(async () => {
    try {
      console.log('Mikrofon izni kontrol ediliyor...');
      const stream = await navigator.mediaDevices.getUserMedia({ 
        audio: {
          echoCancellation: true,
          noiseSuppression: true,
          autoGainControl: true
        } 
      });
      stream.getTracks().forEach(track => track.stop());
      console.log('Mikrofon izni alındı');
      setMicPermission(true);
      setError('');
      return true;
    } catch (err) {
      console.error('Mikrofon izni hatası:', err);
      setError('Microphone access was denied. Please allow this application.');
      setMicPermission(false);
      return false;
    }
  }, []);
  // Yeni mesajları getirme fonksiyonu
  const fetchNewMessages = useCallback(async () => {
    if (!meetingInfo?.id || !userInfo?.id) {
      console.log('Meeting veya user bilgisi eksik:', { meetingInfo, userInfo });
      return;
    }

    try {
      console.log('Fetching messages:', {
        meetingId: meetingInfo.id,
        userId: userInfo.id,
        lastMessageId: lastFetchedMessageId
      });

      const response = await apiCall(`/api/messages?meetingId=${meetingInfo.id}&userId=${userInfo.id}&lastMessageId=${lastFetchedMessageId}`);
      
      console.log('Fetched messages:', response);

      if (response.success && response.messages?.length > 0) {
        messageQueueRef.current.push(...response.messages);
        setLastFetchedMessageId(response.metadata.lastMessageId);
      }
    } catch (error) {
      console.error('Message fetch error:', error);
    }
  }, [meetingInfo?.id, userInfo?.id, lastFetchedMessageId]);

  // Mesajları gösterme fonksiyonu
  const displayNextMessage = useCallback(() => {
    if (messageQueueRef.current.length === 0) {
      fetchNewMessages();
      return;
    }
    
    const messages = messageQueueRef.current;
    messageQueueRef.current = []; // Kuyruğu temizle

    setDisplayedMessages(prev => {
      // Önceki ve yeni mesajları birleştir
      const allMessages = [...prev];
      
      // Yeni mesajları ekle (tekrar etmeyenleri)
      messages.forEach(message => {
        if (!allMessages.some(m => m.id === message.id)) {
          allMessages.push(message);
        }
      });

      // Tarihe göre sırala (en yeni üstte)
      return allMessages.sort((a, b) => 
        new Date(b.createdAt) - new Date(a.createdAt)
      );
    });

    displayTimeoutRef.current = setTimeout(() => {
      displayNextMessage();
    }, DISPLAY_DURATION);
  }, [fetchNewMessages]);

  // Speech Recognition temizleme
  const cleanupRecognizers = useCallback(() => {
    console.log('Recognizer\'lar temizleniyor...');
    Object.values(recognizers.current).forEach(recognizer => {
      if (recognizer) {
        try {
          recognizer.stopContinuousRecognitionAsync(
            () => {
              recognizer.close();
              console.log('Recognizer başarıyla temizlendi');
            },
            (err) => console.warn('Recognizer temizleme uyarısı:', err)
          );
        } catch (err) {
          console.warn('Recognizer temizleme hatası:', err);
        }
      }
    });
    recognizers.current = {};
  }, []);

  // Dinlemeyi durdur
  const stopListening = useCallback(async () => {
    try {
      if (!isListening) return;
      console.log('Dinleme durduruluyor...');
  
      const stopPromises = Object.values(recognizers.current).map(recognizer => {
        return new Promise((resolve) => {
          if (!recognizer) {
            resolve();
            return;
          }
          recognizer.stopContinuousRecognitionAsync(
            () => {
              console.log('Recognizer durduruldu');
              resolve();
            },
            (error) => {
              console.warn('Recognizer durdurma uyarısı:', error);
              resolve();
            }
          );
        });
      });
  
      await Promise.all(stopPromises);
      setIsListening(false);
  
      // NOT: Meeting durumunu değiştirmiyoruz
      console.log('Dinleme durduruldu, meeting durumu korunuyor');
      
    } catch (err) {
      console.error('Dinlemeyi durdurma hatası:', err);
      setIsListening(false);
    }
  }, [isListening]);

  // Konuşma işleme
  const processRecognitionResult = useCallback(async (lang, transcript, confidence) => {
    if (!transcript?.trim() || isProcessing.current) {
      console.log('İşlem atlandı - boş metin veya işlem devam ediyor');
      return;
    }
  
    const currentMeeting = currentMeetingDataRef.current;
    if (!currentMeeting?.id) {
      console.error('Meeting bilgisi eksik');
      return;
    }
  
    isProcessing.current = true;
  
    try {
      const cleanText = transcript.trim();
      console.log('Konuşma kaydediliyor:', {
        text: cleanText,
        meetingId: currentMeeting.id,
        userId: userInfo.id,
        userName: userInfo.displayName,
        lang: lang.code,
        confidence
      });
  
      const speechData = {
        meetingId: currentMeeting.id,
        userId: userInfo.id,
        userName: userInfo.displayName || userInfo.userPrincipalName || 'Unknown User',
        sourceText: cleanText,
        confidence: parseFloat(confidence),
        sourceLanguageId: lang.code,
        sourceWordCount: cleanText.split(/\s+/).length
      };
  
      const speechResponse = await apiCall('/api/speech-records', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json; charset=utf8mb4'
        },
        body: JSON.stringify(speechData)
      });
  
      console.log('Speech kayıt yanıtı:', speechResponse);
  
      if (speechResponse.success && speechResponse.recordId) {
        // Önce kaynak dildeki metni translations tablosuna ekle
        await apiCall('/api/translations', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json; charset=utf8mb4'
          },
          body: JSON.stringify({
            recordId: speechResponse.recordId,
            languageId: lang.code, // Kaynak dilin kodu
            translatedText: cleanText, // Orijinal metin
            meetingId: currentMeeting.id,
            wordCount: cleanText.split(/\s+/).length
          })
        });
  
        // Sonra diğer dillere çeviriyi yap
        await Promise.all(SUPPORTED_LANGUAGES
          .filter(targetLang => targetLang.code !== lang.code)
          .map(async targetLang => {
            const translation = await translateText(
              cleanText,
              lang.shortCode,
              targetLang.shortCode
            );
  
            if (translation && translation !== 'Çeviri başarısız') {
              return apiCall('/api/translations', {
                method: 'POST',
                headers: {
                  'Content-Type': 'application/json; charset=utf8mb4'
                },
                body: JSON.stringify({
                  recordId: speechResponse.recordId,
                  languageId: targetLang.code,
                  translatedText: translation,
                  meetingId: currentMeeting.id,
                  wordCount: translation.split(/\s+/).length
                })
              });
            }
          }));
  
        console.log('Tüm çeviriler ve kaynak metin kaydedildi');
      }
    } catch (error) {
      console.error('İşlem hatası:', error);
      setError('Konuşma kaydedilemedi: ' + error.message);
    } finally {
      isProcessing.current = false;
    }
  }, [userInfo, translateText]);

  // Speech Recognition başlatma
  const initializeSpeechRecognition = useCallback(() => {
    return new Promise((resolve, reject) => {
      try {
        console.log('Speech recognition başlatılıyor...', {
          meetingInfo: currentMeetingDataRef.current,
          isActive: isMeetingActive,
          isInitialized: isTeamsMeetingInitialized.current
        });
  
        if (!currentMeetingDataRef.current?.id) {
          throw new Error('Meeting bilgisi eksik');
        }
  
        cleanupRecognizers();
  
        const selectedLang = SUPPORTED_LANGUAGES.find(lang => lang.code === selectedLanguage);
        if (!selectedLang) throw new Error(`Desteklenmeyen dil: ${selectedLanguage}`);
  
        const speechConfig = sdk.SpeechConfig.fromSubscription(SPEECH_KEY, SPEECH_REGION);
        speechConfig.speechRecognitionLanguage = selectedLang.code;
  
        // Sessizlik ayarları
        speechConfig.setProperty(
          sdk.PropertyId.SpeechServiceConnection_InitialSilenceTimeoutMs,
          "1000"
        );
        
        speechConfig.setProperty(
          sdk.PropertyId.SpeechServiceConnection_EndSilenceTimeoutMs,
          "250"
        );

        speechConfig.setProperty(
          sdk.PropertyId.SpeechServiceResponse_RequestSentenceBoundary,
          "true"
        );

        speechConfig.setProperty(
          sdk.PropertyId.Speech_SegmentationSilenceTimeoutMs,
          "500"  // Segment arası sessizlik süresi
        );
  
        const audioConfig = sdk.AudioConfig.fromDefaultMicrophoneInput();
        const recognizer = new sdk.SpeechRecognizer(speechConfig, audioConfig);
  
        // Event handler'ları
        recognizer.recognized = async (s, e) => {
          if (e.result.reason === sdk.ResultReason.RecognizedSpeech) {
            const transcript = e.result.text?.trim();
            const confidence = e.result.confidence || 0.8;
            
            // Her tanıma işleminde meeting durumunu kontrol et
            if (!isMeetingActive) {
              console.log('Meeting aktif olmadığı için dinleme durduruluyor');
              stopListening();
              return;
            }
        
            if (transcript && confidence > 0.3) {
              console.log('Konuşma tanındı:', { transcript, confidence });
              await processRecognitionResult(selectedLang, transcript, confidence);
            }
          }
        };
  
        recognizer.recognizing = (s, e) => {
          if (e.result.text) {
            console.log('Tanıma devam ediyor:', e.result.text);
          }
        };
  
        // PhraseList ekle
        if (selectedLang.phraseList?.length > 0) {
          const phraseList = sdk.PhraseListGrammar.fromRecognizer(recognizer);
          selectedLang.phraseList.forEach(phrase => phraseList.addPhrase(phrase));
        }
  
        recognizers.current[selectedLang.code] = recognizer;
        console.log('Speech recognition başarıyla başlatıldı');
        resolve();
  
      } catch (err) {
        console.error('Speech recognition başlatma hatası:', err);
        setError('Speech recognition could not be started: ' + err.message);
        reject(err);
      }
    });
  }, [selectedLanguage, processRecognitionResult, cleanupRecognizers]);
  
  // Dinlemeyi başlat
  const startListening = useCallback(async () => {
    try {
        
      if (isListening) return;
      console.log('Dinleme başlatılıyor...', { userInfo });
      
      if (!isTeamsClient) {
        throw new Error('Cannot be run outside of Micrososft Teams Desktop');
      }
  
      if (!speechServiceReady) {
        throw new Error('The speech service is not ready');
      }
  
      const hasMicPermission = await checkMicrophonePermission();
      if (!hasMicPermission) {
        throw new Error('No microphone permission');
      }
  
      await stopListening();
  
      // Meeting başlatma
      const context = await microsoftTeams.app.getContext();
      if (!context?.meeting?.id) {
        throw new Error('Meeting ID could not be found');
      }
  
      const meetingData = {
        id: context.meeting.id,
        name: context.meeting.name || 'Teams Meeting'
      };
  
      // Meeting'i aktifleştir
      const response = await apiCall('/api/meetings', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          meetingId: meetingData.id,
          meetingName: meetingData.name,
          hostUserId: userInfo?.id,
          hostUserName: userInfo?.displayName,
          status: 'active'
        })
      });
  
      if (!response.success) {
        throw new Error('The meeting could not be started');
      }
  
      // Ref'leri güncelle
      currentMeetingDataRef.current = meetingData;
      isMeetingActiveRef.current = true;
      isTeamsMeetingInitialized.current = true;
  
      // State'leri güncelle
      setMeetingInfo(meetingData);
      setIsMeetingActive(true);
  
      // State güncellemelerinin tamamlanmasını bekle
      await new Promise(resolve => setTimeout(resolve, 100));
  
      // Kullanıcı dil tercihini kaydet
      await apiCall('/api/user-preferences', {
        method: 'POST',
        body: JSON.stringify({
          meetingId: meetingData.id,
          userId: userInfo.id,
          languageId: selectedLanguage
        })
      });
  
      console.log('Meeting durumu güncellendi:', {
        meetingData,
        isActive: isMeetingActiveRef.current,
        isInitialized: isTeamsMeetingInitialized.current
      });
  
      // Speech recognition başlat
      await initializeSpeechRecognition();
  
      const currentRecognizer = recognizers.current[selectedLanguage];
      if (!currentRecognizer) {
        throw new Error('Recognizer could not be found');
      }
  
      await new Promise((resolve, reject) => {
        currentRecognizer.startContinuousRecognitionAsync(
          () => {
            console.log('Dinleme başarıyla başlatıldı, durum:', {
              isActive: isMeetingActiveRef.current,
              meetingInfo: currentMeetingDataRef.current
            });
            setIsListening(true);
            resolve();
          },
          (error) => {
            console.error('Dinleme başlatma hatası:', error);
            reject(error);
          }
        );
      });
      
  
    } catch (err) {
      console.error('Dinleme başlatma hatası:', err);
      setError(err.message);
      await stopListening();
    }
  }, [
    isListening,
    isTeamsClient,
    speechServiceReady,
    userInfo,
    selectedLanguage,
    checkMicrophonePermission,
    stopListening,
    initializeSpeechRecognition
  ]);

  // Teams başlatma
  const initializeTeams = useCallback(async () => {
    if (isTeamsInitialized.current) {
      console.log('Teams zaten başlatılmış, atlanıyor...');
      return true;
    }
  
    try {
      console.log('Teams başlatılıyor...');
      await microsoftTeams.app.initialize();
      const context = await microsoftTeams.app.getContext();
      
      if (!context?.user) {
        throw new Error('Teams user information could not be found');
      }
  
      console.log('Teams kullanıcı bilgileri alındı:', context.user);
      
      const userInfoData = {
        displayName: context.user.displayName || '',
        email: context.user.email,
        id: context.user.id,
        userPrincipalName: context.user.userPrincipalName
      };
  
      setUserInfo(userInfoData);
      setUserName(context.user.userPrincipalName || context.user.displayName);
      isTeamsInitialized.current = true;
  
      // userInfo'nun set edilmesini bekle
      await new Promise(resolve => setTimeout(resolve, 100));
  
      return true;
    } catch (err) {
      console.error('Teams başlatma hatası:', err);
      isTeamsInitialized.current = false;
      return false;
    }
  }, []);

// Meeting durumu değişikliklerini izle
useEffect(() => {
  console.log('Meeting durumu:', {
    isActive: isMeetingActive,
    meetingInfo,
    meetingRef: currentMeetingDataRef.current,
    isInitialized: isTeamsMeetingInitialized.current
  });
}, [isMeetingActive, meetingInfo]);

  useEffect(() => {
    console.log('Meeting durumu değişti:', {
      isActive: isMeetingActive,
      meetingInfo,
      meetingRef: currentMeetingDataRef.current
    });
  
    // Meeting aktif değilse ve dinleme devam ediyorsa durdur
    if (!isMeetingActive && isListening) {
      stopListening();
    }
  }, [isMeetingActive, isListening, stopListening, meetingInfo]);

  useEffect(() => {
    return () => {
      if (currentMeetingDataRef.current?.id) {
        stopListening();
      }
    };
  }, [stopListening]);
  
  // cleanupMeeting fonksiyonunu sadece component unmount olduğunda çağır
  useEffect(() => {
    return () => {
      if (isListening) {
        stopListening();
      }
    };
  }, [stopListening, isListening]);

  // Meeting temizleme
  // Ana başlatma effect'i
  useEffect(() => {
    let mounted = true;
 
    const initializeApp = async () => {
        setIsInitializing(true);
        console.log('Uygulama başlatılıyor...');
 
        try {
            const isTeams = await checkTeamsContext();
            if (!isTeams) {
                throw new Error('CSC Voice AI works only on the Teams Desktop Client.');
            }
 
            const isSpeechReady = await checkSpeechService();
            if (!isSpeechReady) {
                throw new Error('The speech service could not be started.');
            }
 
            const teamsInitialized = await initializeTeams();
            if (!teamsInitialized) {
                throw new Error('Teams could not be started.');
            }
 
            await new Promise(resolve => {
                microsoftTeams.app.initialize().then(resolve);
            });
 
            if (!mounted) return;
 
            const context = await microsoftTeams.app.getContext();
            console.log('Teams context alındı:', context);
 
            if (!context?.user?.tenant?.id) {
                throw new Error('Teams tenant bilgisi alınamadı');
            }
 
            const subscriptionId = context.user.id || context.user.licenseType;
            if (!subscriptionId) {
                throw new Error('Teams user bilgisi alınamadı');
            }
 
            const subscriptionCheck = await fetch(`${API_BASE_URL}/api/subscriptionCheck`, {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({
                    subscriptionId,
                    tenantId: context.user.tenant.id,
                    meetingId: context.meeting.id
                })
            });
 
            const result = await subscriptionCheck.json();
            if (!result.success) {
                setButtonsDisabled(true);
                throw new Error(result.error || 'Subscription kontrolü başarısız');
            }
 
            if (!result.canUseMeeting) {
                setButtonsDisabled(true);
                throw new Error(
                    `Paket limitine ulaşıldı. ${result.subscription.packageName} paketinizde 
                    ${result.subscription.meetingLimit} toplantı hakkı bulunmaktadır. 
                    Şu ana kadar ${result.subscription.meetingsUsed} toplantı kullanıldı. 
                    Lütfen www.cscvoice.ai adresinden paket yükseltmesi yapınız.`
                );
            }
 
            setButtonsDisabled(false);
 
            if (context?.meeting?.id) {
                const meetingData = {
                    id: context.meeting.id,
                    name: context.meeting.name || 'Teams Meeting'
                };
 
                currentMeetingDataRef.current = meetingData;
                setMeetingInfo(meetingData);
 
                await apiCall('/api/meetings', {
                    method: 'POST',
                    headers: { 'Content-Type': 'application/json' },
                    body: JSON.stringify({
                        meetingId: meetingData.id,
                        meetingName: meetingData.name,
                        hostUserId: userInfo?.id,
                        hostUserName: userInfo?.displayName,
                        status: 'active'
                    })
                });
 
                if (userInfo?.id) {
                    await apiCall('/api/user-preferences', {
                        method: 'POST',
                        body: JSON.stringify({
                            meetingId: meetingData.id,
                            userId: userInfo.id,
                            languageId: selectedLanguage
                        })
                    });
                }
            }
 
            const hasMicPermission = await checkMicrophonePermission();
            if (!hasMicPermission) {
                throw new Error('Microphone permission could not be obtained.');
            }
 
            console.log('Uygulama başarıyla başlatıldı');
 
        } catch (err) {
            console.error('Başlatma hatası:', err);
            if (mounted) {
                setError(err.message);
            }
        } finally {
            if (mounted) {
                setIsInitializing(false);
            }
        }
    };
 
    initializeApp();
 
    return () => {
        mounted = false;
        if (isListening) {
            stopListening();
        }
    };
 }, [
    checkTeamsContext,
    checkSpeechService,
    initializeTeams,
    checkMicrophonePermission,
    stopListening,
    userInfo,
    selectedLanguage,
    isListening
 ]);

  // Mesaj gösterme effect'i
  useEffect(() => {
    if (isMeetingActive && userInfo) {
      displayNextMessage();
      
      const interval = setInterval(fetchNewMessages, 100);
      
      return () => {
        clearInterval(interval);
        if (displayTimeoutRef.current) {
          clearTimeout(displayTimeoutRef.current);
        }
      };
    }
  }, [isMeetingActive, userInfo, displayNextMessage, fetchNewMessages]);

  const TRANSLATIONSREPORT = {
    'ar-SA': {
    title: "تقرير الاجتماع",
    summary: "ملخص الاجتماع",
    generatedAt: "تم الإنشاء في",
    stats: {
      totalParticipants: "إجمالي المشاركين",
      totalMessages: "إجمالي الرسائل",
      totalWords: "إجمالي الكلمات",
      duration: "مدة الاجتماع",
      minutes: "دقيقة"
    },
    participants: {
      title: "تفاصيل المشاركين",
      columns: {
        name: "المشارك",
        messageCount: "عدد الرسائل",
        wordCount: "عدد الكلمات",
        participation: "نسبة المشاركة"
      }
    },
    mood: {
      title: "أجواء الاجتماع",
      overall: "الحالة العامة",
      ambiance: "أجواء الاجتماع",
      reactions: "ردود الفعل المستخدمة",
      positive: "إيجابي",
      neutral: "محايد",
      negative: "سلبي",
      perParticipant: "الحالة لكل مشارك",
      keywords: "العبارات البارزة"
    },
    footer: {
      generatedBy: "تم إنشاء هذا التقرير تلقائيًا بواسطة CSC Voice AI",
      copyright: "© CSC Bilisim"
    }
  },
  'bg-BG': {
    title: "Доклад от срещата",
    summary: "Обобщение на срещата",
    generatedAt: "Генерирано на",
    stats: {
      totalParticipants: "Общо участници",
      totalMessages: "Общо съобщения",
      totalWords: "Общо думи",
      duration: "Продължителност на срещата",
      minutes: "минути"
    },
    participants: {
      title: "Детайли за участниците",
      columns: {
        name: "Участник",
        messageCount: "Брой съобщения",
        wordCount: "Брой думи",
        participation: "Степен на участие"
      }
    },
    mood: {
      title: "Атмосфера на срещата",
      overall: "Общо настроение",
      ambiance: "Атмосфера на срещата",
      reactions: "Използвани реакции",
      positive: "Позитивно",
      neutral: "Неутрално",
      negative: "Негативно",
      perParticipant: "Настроение по участници",
      keywords: "Подчертани изрази"
    },
    footer: {
      generatedBy: "Този доклад е автоматично генериран от CSC Voice AI",
      copyright: "© CSC Bilisim"
    }
  },
  'cs-CZ': {
    title: "Zpráva ze schůzky",
    summary: "Shrnutí schůzky",
    generatedAt: "Vytvořeno",
    stats: {
      totalParticipants: "Celkem účastníků",
      totalMessages: "Celkem zpráv",
      totalWords: "Celkem slov",
      duration: "Doba trvání schůzky",
      minutes: "minut"
    },
    participants: {
      title: "Detaily účastníků",
      columns: {
        name: "Účastník",
        messageCount: "Počet zpráv",
        wordCount: "Počet slov",
        participation: "Míra účasti"
      }
    },
    mood: {
      title: "Atmosféra schůzky",
      overall: "Celková nálada",
      ambiance: "Atmosféra schůzky",
      reactions: "Použité reakce",
      positive: "Pozitivní",
      neutral: "Neutrální",
      negative: "Negativní",
      perParticipant: "Nálada podle účastníků",
      keywords: "Zvýrazněné výrazy"
    },
    footer: {
      generatedBy: "Tato zpráva byla automaticky vygenerována pomocí CSC Voice AI",
      copyright: "© CSC Bilisim"
    }
  },
  'da-DK': {
    title: "Møderefereat",
    summary: "Mødesammenfatning",
    generatedAt: "Genereret den",
    stats: {
      totalParticipants: "Samlet antal deltagere",
      totalMessages: "Samlet antal beskeder",
      totalWords: "Samlet antal ord",
      duration: "Mødets varighed",
      minutes: "minutter"
    },
    participants: {
      title: "Deltagerdetaljer",
      columns: {
        name: "Deltager",
        messageCount: "Antal beskeder",
        wordCount: "Antal ord",
        participation: "Deltagelsesgrad"
      }
    },
    mood: {
      title: "Mødeatmosfære",
      overall: "Samlet stemning",
      ambiance: "Mødeatmosfære",
      reactions: "Anvendte reaktioner",
      positive: "Positiv",
      neutral: "Neutral",
      negative: "Negativ",
      perParticipant: "Stemning pr. deltager",
      keywords: "Fremhævede udtryk"
    },
    footer: {
      generatedBy: "Denne rapport er automatisk genereret af CSC Voice AI",
      copyright: "© CSC Bilisim"
    }
  },
  'de-DE': {
    title: "Besprechungsbericht",
    summary: "Besprechungszusammenfassung",
    generatedAt: "Erstellt am",
    stats: {
      totalParticipants: "Gesamtteilnehmer",
      totalMessages: "Gesamtnachrichten",
      totalWords: "Gesamtwörter",
      duration: "Besprechungsdauer",
      minutes: "Minuten"
    },
    participants: {
      title: "Teilnehmerdetails",
      columns: {
        name: "Teilnehmer",
        messageCount: "Nachrichtenanzahl",
        wordCount: "Wortanzahl",
        participation: "Teilnahmequote"
      }
    },
    mood: {
      title: "Besprechungsatmosphäre",
      overall: "Gesamtstimmung",
      ambiance: "Besprechungsambiente",
      reactions: "Verwendete Reaktionen",
      positive: "Positiv",
      neutral: "Neutral",
      negative: "Negativ",
      perParticipant: "Stimmung pro Teilnehmer",
      keywords: "Hervorgehobene Ausdrücke"
    },
    footer: {
      generatedBy: "Dieser Bericht wurde automatisch von CSC Voice AI generiert",
      copyright: "© CSC Bilisim"
    }
  },
    'el-GR': {
      title: "Αναφορά Συνάντησης",
      summary: "Περίληψη Συνάντησης",
      generatedAt: "Δημιουργήθηκε στις",
      stats: {
        totalParticipants: "Συνολικοί Συμμετέχοντες",
        totalMessages: "Συνολικά Μηνύματα",
        totalWords: "Συνολικές Λέξεις",
        duration: "Διάρκεια Συνάντησης",
        minutes: "λεπτά"
      },
      participants: {
        title: "Λεπτομέρειες Συμμετεχόντων",
        columns: {
          name: "Συμμετέχων",
          messageCount: "Αριθμός Μηνυμάτων",
          wordCount: "Αριθμός Λέξεων",
          participation: "Ποσοστό Συμμετοχής"
        }
      },
      mood: {
        title: "Ατμόσφαιρα Συνάντησης",
        overall: "Συνολική Διάθεση",
        ambiance: "Ατμόσφαιρα Συνάντησης",
        reactions: "Χρησιμοποιημένες Αντιδράσεις",
        positive: "Θετικό",
        neutral: "Ουδέτερο",
        negative: "Αρνητικό",
        perParticipant: "Διάθεση ανά Συμμετέχοντα",
        keywords: "Βασικές Εκφράσεις"
      },
      footer: {
        generatedBy: "Η αναφορά δημιουργήθηκε αυτόματα από το CSC Voice AI",
        copyright: "© CSC Bilisim"
      }
    },
    'en-US': {
      title: "Meeting Report",
      summary: "Meeting Summary",
      generatedAt: "Generated At",
      stats: {
        totalParticipants: "Total Participants",
        totalMessages: "Total Messages",
        totalWords: "Total Words",
        duration: "Meeting Duration",
        minutes: "minutes"
      },
      participants: {
        title: "Participant Details",
        columns: {
          name: "Participant",
          messageCount: "Message Count",
          wordCount: "Word Count",
          participation: "Participation Rate"
        }
      },
      mood: {
        title: "Meeting Mood",
        overall: "Overall Mood",
        ambiance: "Meeting Ambiance",
        reactions: "Used Reactions",
        positive: "Positive",
        neutral: "Neutral",
        negative: "Negative",
        perParticipant: "Mood Per Participant",
        keywords: "Highlighted Expressions"
      },
      footer: {
        generatedBy: "This report was automatically generated by CSC Voice AI",
        copyright: "© CSC Bilisim"
      }
    },
    'es-ES': {
      title: "Informe de la Reunión",
      summary: "Resumen de la Reunión",
      generatedAt: "Generado el",
      stats: {
        totalParticipants: "Total de Participantes",
        totalMessages: "Total de Mensajes",
        totalWords: "Total de Palabras",
        duration: "Duración de la Reunión",
        minutes: "minutos"
      },
      participants: {
        title: "Detalles de los Participantes",
        columns: {
          name: "Participante",
          messageCount: "Cantidad de Mensajes",
          wordCount: "Cantidad de Palabras",
          participation: "Porcentaje de Participación"
        }
      },
      mood: {
        title: "Ambiente de la Reunión",
        overall: "Estado General",
        ambiance: "Ambiente de la Reunión",
        reactions: "Reacciones Utilizadas",
        positive: "Positivo",
        neutral: "Neutral",
        negative: "Negativo",
        perParticipant: "Estado por Participante",
        keywords: "Expresiones Destacadas"
      },
      footer: {
        generatedBy: "Este informe fue generado automáticamente por CSC Voice AI",
        copyright: "© CSC Bilisim"
      }
    },
    'fi-FI': {
      title: "Kokousraportti",
      summary: "Kokouksen Yhteenveto",
      generatedAt: "Luotu",
      stats: {
        totalParticipants: "Osallistujien Määrä",
        totalMessages: "Viestien Määrä",
        totalWords: "Sanamäärä",
        duration: "Kokouksen Kesto",
        minutes: "minuuttia"
      },
      participants: {
        title: "Osallistujien Tiedot",
        columns: {
          name: "Osallistuja",
          messageCount: "Viestien Lukumäärä",
          wordCount: "Sanamäärä",
          participation: "Osallistumisaste"
        }
      },
      mood: {
        title: "Kokouksen Tunnelma",
        overall: "Yleinen Tunnelma",
        ambiance: "Kokouksen Ilmapiiri",
        reactions: "Käytetyt Reaktiot",
        positive: "Positiivinen",
        neutral: "Neutraali",
        negative: "Negatiivinen",
        perParticipant: "Tunnelma Osallistujittain",
        keywords: "Avainsanat"
      },
      footer: {
        generatedBy: "Tämä raportti luotiin automaattisesti CSC Voice AI:n avulla",
        copyright: "© CSC Bilisim"
      }
    },
    'fr-FR': {
      title: "Rapport de Réunion",
      summary: "Résumé de la Réunion",
      generatedAt: "Généré le",
      stats: {
        totalParticipants: "Participants au Total",
        totalMessages: "Messages au Total",
        totalWords: "Mots au Total",
        duration: "Durée de la Réunion",
        minutes: "minutes"
      },
      participants: {
        title: "Détails des Participants",
        columns: {
          name: "Participant",
          messageCount: "Nombre de Messages",
          wordCount: "Nombre de Mots",
          participation: "Taux de Participation"
        }
      },
      mood: {
        title: "Ambiance de la Réunion",
        overall: "Humeur Générale",
        ambiance: "Ambiance de la Réunion",
        reactions: "Réactions Utilisées",
        positive: "Positif",
        neutral: "Neutre",
        negative: "Négatif",
        perParticipant: "Humeur par Participant",
        keywords: "Expressions Clés"
      },
      footer: {
        generatedBy: "Ce rapport a été généré automatiquement par CSC Voice AI",
        copyright: "© CSC Bilisim"
      }
    },
    'he-IL': {
      title: "דו\"ח פגישה",
      summary: "סיכום פגישה",
      generatedAt: "נוצר ב",
      stats: {
        totalParticipants: "סה\"כ משתתפים",
        totalMessages: "סה\"כ הודעות",
        totalWords: "סה\"כ מילים",
        duration: "משך הפגישה",
        minutes: "דקות"
      },
      participants: {
        title: "פרטי משתתפים",
        columns: {
          name: "משתתף",
          messageCount: "מספר הודעות",
          wordCount: "מספר מילים",
          participation: "אחוז השתתפות"
        }
      },
      mood: {
        title: "אווירת הפגישה",
        overall: "מצב כללי",
        ambiance: "אווירה כללית",
        reactions: "תגובות בשימוש",
        positive: "חיובי",
        neutral: "נייטרלי",
        negative: "שלילי",
        perParticipant: "מצב לפי משתתף",
        keywords: "מילות מפתח"
      },
      footer: {
        generatedBy: "הדוח נוצר אוטומטית על ידי CSC Voice AI",
        copyright: "© CSC Bilisim"
      }
    },
    'hu-HU': {
      title: "Találkozói Jelentés",
      summary: "Találkozó Összefoglaló",
      generatedAt: "Létrehozva",
      stats: {
        totalParticipants: "Összes Résztvevő",
        totalMessages: "Összes Üzenet",
        totalWords: "Összes Szó",
        duration: "Találkozó Időtartama",
        minutes: "perc"
      },
      participants: {
        title: "Résztvevők Adatai",
        columns: {
          name: "Résztvevő",
          messageCount: "Üzenetek Száma",
          wordCount: "Szavak Száma",
          participation: "Részvételi Arány"
        }
      },
      mood: {
        title: "Találkozó Hangulata",
        overall: "Általános Hangulat",
        ambiance: "Találkozói Atmoszféra",
        reactions: "Használt Reakciók",
        positive: "Pozitív",
        neutral: "Semleges",
        negative: "Negatív",
        perParticipant: "Hangulat Résztvevőnként",
        keywords: "Kulcsszavak"
      },
      footer: {
        generatedBy: "Ezt a jelentést automatikusan készítette a CSC Voice AI",
        copyright: "© CSC Bilisim"
      }
    },
    'it-IT': {
      title: "Rapporto Riunione",
      summary: "Sommario della Riunione",
      generatedAt: "Generato il",
      stats: {
        totalParticipants: "Totale Partecipanti",
        totalMessages: "Totale Messaggi",
        totalWords: "Totale Parole",
        duration: "Durata della Riunione",
        minutes: "minuti"
      },
      participants: {
        title: "Dettagli Partecipanti",
        columns: {
          name: "Partecipante",
          messageCount: "Conteggio Messaggi",
          wordCount: "Conteggio Parole",
          participation: "Tasso di Partecipazione"
        }
      },
      mood: {
        title: "Atmosfera della Riunione",
        overall: "Umore Generale",
        ambiance: "Atmosfera della Riunione",
        reactions: "Reazioni Utilizzate",
        positive: "Positivo",
        neutral: "Neutro",
        negative: "Negativo",
        perParticipant: "Umore per Partecipante",
        keywords: "Espressioni Chiave"
      },
      footer: {
        generatedBy: "Questo rapporto è stato generato automaticamente da CSC Voice AI",
        copyright: "© CSC Bilisim"
      }
    },
    'ja-JP': {
      title: "会議レポート",
      summary: "会議の概要",
      generatedAt: "生成日",
      stats: {
        totalParticipants: "総参加者数",
        totalMessages: "総メッセージ数",
        totalWords: "総単語数",
        duration: "会議の時間",
        minutes: "分"
      },
      participants: {
        title: "参加者の詳細",
        columns: {
          name: "参加者",
          messageCount: "メッセージ数",
          wordCount: "単語数",
          participation: "参加率"
        }
      },
      mood: {
        title: "会議の雰囲気",
        overall: "全体的なムード",
        ambiance: "会議の雰囲気",
        reactions: "使用されたリアクション",
        positive: "ポジティブ",
        neutral: "ニュートラル",
        negative: "ネガティブ",
        perParticipant: "参加者ごとのムード",
        keywords: "重要な表現"
      },
      footer: {
        generatedBy: "このレポートはCSC Voice AIによって自動生成されました",
        copyright: "© CSC Bilisim"
      }
    },
    'ko-KR': {
      title: "회의 보고서",
      summary: "회의 요약",
      generatedAt: "생성일",
      stats: {
        totalParticipants: "총 참여자 수",
        totalMessages: "총 메시지 수",
        totalWords: "총 단어 수",
        duration: "회의 시간",
        minutes: "분"
      },
      participants: {
        title: "참여자 세부정보",
        columns: {
          name: "참여자",
          messageCount: "메시지 수",
          wordCount: "단어 수",
          participation: "참여율"
        }
      },
      mood: {
        title: "회의 분위기",
        overall: "전체 분위기",
        ambiance: "회의 분위기",
        reactions: "사용된 반응",
        positive: "긍정적",
        neutral: "중립적",
        negative: "부정적",
        perParticipant: "참여자별 분위기",
        keywords: "핵심 표현"
      },
      footer: {
        generatedBy: "이 보고서는 CSC Voice AI에 의해 자동 생성되었습니다",
        copyright: "© CSC Bilisim"
      }
    },
    'ms-MY': {
    title: "Laporan Mesyuarat",
    summary: "Ringkasan Mesyuarat",
    generatedAt: "Dijana pada",
    stats: {
      totalParticipants: "Jumlah Peserta",
      totalMessages: "Jumlah Mesej",
      totalWords: "Jumlah Perkataan",
      duration: "Tempoh Mesyuarat",
      minutes: "minit"
    },
    participants: {
      title: "Maklumat Peserta",
      columns: {
        name: "Peserta",
        messageCount: "Bilangan Mesej",
        wordCount: "Bilangan Perkataan",
        participation: "Kadar Penyertaan"
      }
    },
    mood: {
      title: "Suasana Mesyuarat",
      overall: "Keadaan Keseluruhan",
      ambiance: "Suasana Mesyuarat",
      reactions: "Reaksi Digunakan",
      positive: "Positif",
      neutral: "Neutral",
      negative: "Negatif",
      perParticipant: "Keadaan Mengikut Peserta",
      keywords: "Ungkapan Penting"
    },
    footer: {
      generatedBy: "Laporan ini dijana secara automatik oleh CSC Voice AI",
      copyright: "© CSC Bilisim"
    }
  },
  'pl-PL': {
    title: "Raport Spotkania",
    summary: "Podsumowanie Spotkania",
    generatedAt: "Wygenerowano dnia",
    stats: {
      totalParticipants: "Łączna liczba uczestników",
      totalMessages: "Łączna liczba wiadomości",
      totalWords: "Łączna liczba słów",
      duration: "Czas trwania spotkania",
      minutes: "minut"
    },
    participants: {
      title: "Szczegóły Uczestników",
      columns: {
        name: "Uczestnik",
        messageCount: "Liczba wiadomości",
        wordCount: "Liczba słów",
        participation: "Poziom Uczestnictwa"
      }
    },
    mood: {
      title: "Atmosfera Spotkania",
      overall: "Ogólny Nastrój",
      ambiance: "Atmosfera Spotkania",
      reactions: "Używane reakcje",
      positive: "Pozytywne",
      neutral: "Neutralne",
      negative: "Negatywne",
      perParticipant: "Nastrój według uczestnika",
      keywords: "Kluczowe frazy"
    },
    footer: {
      generatedBy: "Ten raport został wygenerowany automatycznie przez CSC Voice AI",
      copyright: "© CSC Bilisim"
    }
  },
  'pt-PT': {
    title: "Relatório da Reunião",
    summary: "Resumo da Reunião",
    generatedAt: "Gerado em",
    stats: {
      totalParticipants: "Total de Participantes",
      totalMessages: "Total de Mensagens",
      totalWords: "Total de Palavras",
      duration: "Duração da Reunião",
      minutes: "minutos"
    },
    participants: {
      title: "Detalhes dos Participantes",
      columns: {
        name: "Participante",
        messageCount: "Número de Mensagens",
        wordCount: "Número de Palavras",
        participation: "Taxa de Participação"
      }
    },
    mood: {
      title: "Clima da Reunião",
      overall: "Estado Geral",
      ambiance: "Clima da Reunião",
      reactions: "Reações Usadas",
      positive: "Positivo",
      neutral: "Neutro",
      negative: "Negativo",
      perParticipant: "Clima por Participante",
      keywords: "Frases Relevantes"
    },
    footer: {
      generatedBy: "Este relatório foi gerado automaticamente pelo CSC Voice AI",
      copyright: "© CSC Bilisim"
    }
  },
  'ro-RO': {
    title: "Raportul Întâlnirii",
    summary: "Rezumatul Întâlnirii",
    generatedAt: "Generat la",
    stats: {
      totalParticipants: "Total Participanți",
      totalMessages: "Total Mesaje",
      totalWords: "Total Cuvinte",
      duration: "Durata Întâlnirii",
      minutes: "minute"
    },
    participants: {
      title: "Detalii Participanți",
      columns: {
        name: "Participant",
        messageCount: "Număr Mesaje",
        wordCount: "Număr Cuvinte",
        participation: "Procent Participare"
      }
    },
    mood: {
      title: "Atmosfera Întâlnirii",
      overall: "Starea Generală",
      ambiance: "Atmosfera Întâlnirii",
      reactions: "Reacții Utilizate",
      positive: "Pozitiv",
      neutral: "Neutru",
      negative: "Negativ",
      perParticipant: "Starea pe Participanți",
      keywords: "Expresii Cheie"
    },
    footer: {
      generatedBy: "Acest raport a fost generat automat de CSC Voice AI",
      copyright: "© CSC Bilisim"
    }
  },
  'ru-RU': {
    title: "Отчет о встрече",
    summary: "Сводка встречи",
    generatedAt: "Создано",
    stats: {
      totalParticipants: "Всего участников",
      totalMessages: "Всего сообщений",
      totalWords: "Всего слов",
      duration: "Длительность встречи",
      minutes: "минут"
    },
    participants: {
      title: "Детали участников",
      columns: {
        name: "Участник",
        messageCount: "Количество сообщений",
        wordCount: "Количество слов",
        participation: "Уровень участия"
      }
    },
    mood: {
      title: "Атмосфера встречи",
      overall: "Общее настроение",
      ambiance: "Атмосфера встречи",
      reactions: "Использованные реакции",
      positive: "Положительное",
      neutral: "Нейтральное",
      negative: "Отрицательное",
      perParticipant: "Настроение по участникам",
      keywords: "Ключевые фразы"
    },
    footer: {
      generatedBy: "Этот отчет был автоматически создан CSC Voice AI",
      copyright: "© CSC Bilisim"
    }
  },
    'sv-SE': {
      title: "Mötesrapport",
      summary: "Mötessammanfattning",
      generatedAt: "Genererat den",
      stats: {
        totalParticipants: "Totalt antal deltagare",
        totalMessages: "Totalt antal meddelanden",
        totalWords: "Totalt antal ord",
        duration: "Mötets längd",
        minutes: "minuter"
      },
      participants: {
        title: "Deltagardetaljer",
        columns: {
          name: "Deltagare",
          messageCount: "Antal meddelanden",
          wordCount: "Antal ord",
          participation: "Deltagandenivå"
        }
      },
      mood: {
        title: "Mötesatmosfär",
        overall: "Övergripande stämning",
        ambiance: "Mötesatmosfär",
        reactions: "Använda reaktioner",
        positive: "Positiv",
        neutral: "Neutral",
        negative: "Negativ",
        perParticipant: "Stämning per deltagare",
        keywords: "Viktiga fraser"
      },
      footer: {
        generatedBy: "Den här rapporten genererades automatiskt av CSC Voice AI",
        copyright: "© CSC Bilisim"
      }
    },
    'th-TH': {
      title: "รายงานการประชุม",
      summary: "สรุปการประชุม",
      generatedAt: "สร้างเมื่อ",
      stats: {
        totalParticipants: "จำนวนผู้เข้าร่วมทั้งหมด",
        totalMessages: "จำนวนข้อความทั้งหมด",
        totalWords: "จำนวนคำทั้งหมด",
        duration: "ระยะเวลาการประชุม",
        minutes: "นาที"
      },
      participants: {
        title: "รายละเอียดผู้เข้าร่วม",
        columns: {
          name: "ผู้เข้าร่วม",
          messageCount: "จำนวนข้อความ",
          wordCount: "จำนวนคำ",
          participation: "ระดับการมีส่วนร่วม"
        }
      },
      mood: {
        title: "บรรยากาศการประชุม",
        overall: "ภาพรวมอารมณ์",
        ambiance: "บรรยากาศการประชุม",
        reactions: "ปฏิกิริยาที่ใช้",
        positive: "เชิงบวก",
        neutral: "เป็นกลาง",
        negative: "เชิงลบ",
        perParticipant: "อารมณ์ตามผู้เข้าร่วม",
        keywords: "คำสำคัญ"
      },
      footer: {
        generatedBy: "รายงานนี้สร้างขึ้นโดยอัตโนมัติโดย CSC Voice AI",
        copyright: "© CSC Bilisim"
      }
    },
    'tr-TR': {
      title: "Toplantı Raporu",
      summary: "Toplantı Özeti",
      generatedAt: "Oluşturulma Tarihi",
      stats: {
        totalParticipants: "Toplam Katılımcı",
        totalMessages: "Toplam Mesaj",
        totalWords: "Toplam Kelime",
        duration: "Toplantı Süresi",
        minutes: "dakika"
      },
      participants: {
        title: "Katılımcı Detayları",
        columns: {
          name: "Katılımcı",
          messageCount: "Mesaj Sayısı",
          wordCount: "Kelime Sayısı",
          participation: "Katılım Oranı"
        }
      },
      mood: {
        title: "Toplantı Atmosferi",
        overall: "Genel Durum",
        ambiance: "Toplantı Atmosferi",
        reactions: "Kullanılan Tepkiler",
        positive: "Pozitif",
        neutral: "Nötr",
        negative: "Negatif",
        perParticipant: "Katılımcıya Göre Durum",
        keywords: "Anahtar İfadeler"
      },
      footer: {
        generatedBy: "Bu rapor CSC Voice AI tarafından otomatik olarak oluşturulmuştur",
        copyright: "© CSC Bilisim"
      }
    },
    'uk-UA': {
      title: "Звіт про зустріч",
      summary: "Резюме зустрічі",
      generatedAt: "Згенеровано",
      stats: {
        totalParticipants: "Загальна кількість учасників",
        totalMessages: "Загальна кількість повідомлень",
        totalWords: "Загальна кількість слів",
        duration: "Тривалість зустрічі",
        minutes: "хвилин"
      },
      participants: {
        title: "Деталі учасників",
        columns: {
          name: "Учасник",
          messageCount: "Кількість повідомлень",
          wordCount: "Кількість слів",
          participation: "Рівень участі"
        }
      },
      mood: {
        title: "Атмосфера зустрічі",
        overall: "Загальний настрій",
        ambiance: "Атмосфера зустрічі",
        reactions: "Використані реакції",
        positive: "Позитивний",
        neutral: "Нейтральний",
        negative: "Негативний",
        perParticipant: "Настрій за учасниками",
        keywords: "Ключові фрази"
      },
      footer: {
        generatedBy: "Цей звіт був автоматично згенерований за допомогою CSC Voice AI",
        copyright: "© CSC Bilisim"
      }
    },
    'vi-VN': {
      title: "Báo Cáo Cuộc Họp",
      summary: "Tóm Tắt Cuộc Họp",
      generatedAt: "Tạo vào",
      stats: {
        totalParticipants: "Tổng số người tham dự",
        totalMessages: "Tổng số tin nhắn",
        totalWords: "Tổng số từ",
        duration: "Thời lượng cuộc họp",
        minutes: "phút"
      },
      participants: {
        title: "Chi Tiết Người Tham Dự",
        columns: {
          name: "Người tham dự",
          messageCount: "Số lượng tin nhắn",
          wordCount: "Số lượng từ",
          participation: "Tỷ lệ tham gia"
        }
      },
      mood: {
        title: "Không Khí Cuộc Họp",
        overall: "Tình Hình Chung",
        ambiance: "Không Khí Cuộc Họp",
        reactions: "Phản ứng đã sử dụng",
        positive: "Tích cực",
        neutral: "Trung lập",
        negative: "Tiêu cực",
        perParticipant: "Tình hình theo từng người tham dự",
        keywords: "Cụm từ nổi bật"
      },
      footer: {
        generatedBy: "Báo cáo này được tạo tự động bởi CSC Voice AI",
        copyright: "© CSC Bilisim"
      }
    },
    'zh-CN': {
      title: "会议报告",
      summary: "会议摘要",
      generatedAt: "生成于",
      stats: {
        totalParticipants: "总参与者",
        totalMessages: "总消息数",
        totalWords: "总字数",
        duration: "会议时长",
        minutes: "分钟"
      },
      participants: {
        title: "参与者详情",
        columns: {
          name: "参与者",
          messageCount: "消息数量",
          wordCount: "单词数量",
          participation: "参与度"
        }
      },
      mood: {
        title: "会议氛围",
        overall: "整体情绪",
        ambiance: "会议氛围",
        reactions: "使用的反应",
        positive: "积极",
        neutral: "中立",
        negative: "消极",
        perParticipant: "每个参与者的情绪",
        keywords: "重点词语"
      },
      footer: {
        generatedBy: "此报告由 CSC Voice AI 自动生成",
        copyright: "© CSC Bilisim"
      }
    }
  };

  const generateMeetingSummary = async () => {
    try {
      const teamsContext = await microsoftTeams.app.getContext();
      const meetingName = teamsContext?.meeting?.name || 'İsimsiz Toplantı';
      
      // Kullanıcının seçili dilini al ve varsayılan dil olarak İngilizce kullan
      const userLanguage = selectedLanguage || 'en-US';
      const translations = TRANSLATIONSREPORT[userLanguage] || TRANSLATIONSREPORT['en-US'];
  
      console.log('Rapor oluşturuluyor:', {
        meetingName,
        language: userLanguage,
        hasTranslations: !!translations
      });
  
      const response = await fetch(`${API_BASE_URL}/api/meetingReport?meetingId=${meetingInfo.id}&language=${selectedLanguage}`);
      const result = await response.json();
  
      if (!result.success) {
        throw new Error(result.error || 'API işlem hatası');
      }
  
      const { data } = result;
      console.log('İşlenmiş API verisi:', data);
  
      // HTML rapor şablonu
      const htmlContent = `
      <!DOCTYPE html>
      <html lang="${userLanguage.split('-')[0]}">
      <head>
        <meta charset="UTF-8">
        <title>${meetingName} - ${translations.title}</title>
          <style>
            body { 
              font-family: Arial, sans-serif; 
              margin: 40px; 
              line-height: 1.6;
              color: #333;
              background-color: #f8f9fa;
            }
            .header { 
              text-align: center; 
              margin-bottom: 30px;
              padding: 20px;
              background: #fff;
              border-radius: 8px;
              border-bottom: 3px solid #0078D4;
              box-shadow: 0 2px 4px rgba(0,0,0,0.1);
            }
            .header h1 {
              color: #0078D4;
              margin-bottom: 10px;
            }
            .summary-section {
              margin: 20px 0;
              padding: 20px;
              background: #fff;
              border-radius: 8px;
              box-shadow: 0 2px 4px rgba(0,0,0,0.1);
            }
            .summary-section h2, 
            .summary-section h3 {
              color: #0078D4;
              border-bottom: 1px solid #eee;
              padding-bottom: 10px;
              margin-top: 20px;
            }
            .summary-section ul {
              list-style-type: none;
              padding-left: 0;
            }
            .summary-section ul li {
              margin: 10px 0;
              padding-left: 20px;
              position: relative;
            }
            .summary-section ul li:before {
              content: "•";
              color: #0078D4;
              font-weight: bold;
              position: absolute;
              left: 0;
            }
            .stats { 
              display: grid;
              grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
              gap: 20px;
              margin: 20px 0;
            }
            .stat-box {
              background: #fff;
              padding: 20px;
              border-radius: 8px;
              box-shadow: 0 2px 4px rgba(0,0,0,0.1);
              text-align: center;
            }
            .stat-box h3 {
              color: #0078D4;
              margin-top: 0;
              margin-bottom: 10px;
              font-size: 16px;
            }
            .stat-box p {
              font-size: 24px;
              font-weight: bold;
              margin: 0;
              color: #2c3e50;
            }
            .table-container {
              margin: 20px 0;
              padding: 20px;
              background: #fff;
              border-radius: 8px;
              box-shadow: 0 2px 4px rgba(0,0,0,0.1);
            }
            table { 
              width: 100%;
              border-collapse: collapse;
              margin: 20px 0;
            }
            th, td { 
              border: 1px solid #dee2e6;
              padding: 12px;
              text-align: left;
            }
            th { 
              background-color: #f8f9fa;
              font-weight: bold;
              color: #0078D4;
            }
            tr:nth-child(even) {
              background-color: #f8f9fa;
            }
            .footer {
              text-align: center;
              margin-top: 40px;
              padding: 20px;
              background: #fff;
              border-radius: 8px;
              box-shadow: 0 2px 4px rgba(0,0,0,0.1);
              color: #666;
            }
            @media print {
              body { background: white; }
              .summary-section, .table-container, .stat-box {
                box-shadow: none;
                border: 1px solid #eee;
              }
            }
          .mood-bars {
            padding: 10px 0;
          }
          
          .mood-bar {
            margin: 8px 0;
          }
          
          .progress {
            height: 20px;
            background-color: #f5f5f5;
            border-radius: 4px;
            overflow: hidden;
          }
          
          .progress-bar {
            height: 100%;
            color: white;
            text-align: center;
            line-height: 20px;
            transition: width 0.6s ease;
          }
          
          .reaction-list {
            display: flex;
            flex-wrap: wrap;
            gap: 10px;
            margin: 10px 0;
          }
          
          .reaction-item {
            background: #f8f9fa;
            padding: 5px 10px;
            border-radius: 20px;
            display: flex;
            align-items: center;
            gap: 5px;
          }
          
          .keyword-cloud {
            display: flex;
            flex-wrap: wrap;
            gap: 8px;
            margin: 10px 0;
          }
          
          .keyword-tag {
            background: #e9ecef;
            padding: 4px 12px;
            border-radius: 15px;
            font-size: 0.9em;
          }
          </style>
        </head>
      <body>
        <div class="header">
          <h1>${meetingName}</h1>
          <p>${translations.title}</p>
          <p>${translations.generatedAt}: ${new Date().toLocaleString(userLanguage)}</p>
        </div>

        ${data.summary ? `
          <div class="summary-section">
            <h2>${translations.summary}</h2>
            ${data.summary.replace(/### /g, '<h3>').replace(/\n/g, '<br>')}
          </div>
        ` : ''}

        <div class="stats">
          <div class="stat-box">
            <h3>${translations.stats.totalParticipants}</h3>
            <p>${data.stats.total_participants || 0}</p>
          </div>
          <div class="stat-box">
            <h3>${translations.stats.totalMessages}</h3>
            <p>${data.stats.total_messages || 0}</p>
          </div>
          <div class="stat-box">
            <h3>${translations.stats.totalWords}</h3>
            <p>${data.stats.total_words || 0}</p>
          </div>
          <div class="stat-box">
            <h3>${translations.stats.duration}</h3>
            <p>${data.stats.duration ? `${data.stats.duration} ${translations.stats.minutes}` : '-'}</p>
          </div>
        </div>

        <div class="table-container">
          <h2>${translations.participants.title}</h2>
          <table>
            <thead>
              <tr>
                <th>${translations.participants.columns.name}</th>
                <th>${translations.participants.columns.messageCount}</th>
                <th>${translations.participants.columns.wordCount}</th>
                <th>${translations.participants.columns.participation}</th>
              </tr>
            </thead>
            <tbody>
              ${data.participants.map(p => `
                <tr>
                  <td>${p.user_name}</td>
                  <td>${p.message_count}</td>
                  <td>${p.word_count}</td>
                  <td>${Math.round((p.message_count / data.stats.total_messages) * 100)}%</td>
                </tr>
              `).join('')}
            </tbody>
          </table>
        </div>

        ${data.mood ? `
          <div class="mood-section">
            <h2>${translations.mood.title}</h2>
            <div class="stats mood-stats">
              <div class="stat-box">
                <h3>${translations.mood.ambiance}</h3>
                <p>${data.mood.ambiance}</p>
              </div>
              <div class="stat-box">
                <h3>${translations.mood.overall}</h3>
                <p>${data.mood.overall}</p>
              </div>
            </div>
            ${data.mood.reactions?.length > 0 ? `
              <div class="reactions">
                <h3>${translations.mood.reactions}</h3>
                <div class="reaction-list">
                  ${data.mood.reactions.join(' ')}
                </div>
              </div>
            ` : ''}
          </div>
        ` : ''}

        <div class="footer">
          <p>${translations.footer.generatedBy}</p>
          <p>${new Date().getFullYear()} ${translations.footer.copyright}</p>
        </div>
      </body>
      </html>
    `;

    // Raporu indir
    const blob = new Blob([htmlContent], { type: 'text/html;charset=utf-8' });
    const url = URL.createObjectURL(blob);
    const link = document.createElement('a');
    link.href = url;
    link.download = `${meetingName.replace(/[^a-z0-9]/gi, '_')}_${translations.title.replace(/\s+/g, '_')}_${new Date().toLocaleDateString(userLanguage)}.html`;
    
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
    URL.revokeObjectURL(url);

    console.log('Rapor başarıyla oluşturuldu');

  } catch (error) {
    console.error('Rapor oluşturma hatası:', {
      error: error.message,
      stack: error.stack
    });
    setError(`Rapor oluşturulamadı: ${error.message}`);
  }
};
  
  return (
    <div className="container-fluid bg-light min-vh-100">
      {/* Navbar */}
      <nav className="navbar navbar-light bg-white shadow-sm sticky-top py-2">
        <div className="container px-2">
          <div className="d-flex align-items-center">
            <img src={colorLogo} alt="Logo" style={{ width: '100px', marginRight: '8px' }} />
            <div>
              <h6 className="mb-0 fw-bold text-primary">CSC Voice AI</h6>
              <small className="text-muted" style={{ fontSize: '0.7rem' }}>Real-time Meeting Translation</small>
            </div>
          </div>
  
          <div className="d-flex align-items-center gap-2 flex-wrap"> {/* flex-wrap eklendi */}
            <select
              value={selectedLanguage}
              onChange={handleLanguageChange}
              disabled={isListening}
              className="form-select form-select-sm"
              style={{ width: '100px', fontSize: '0.8rem' }}
            >
              {SUPPORTED_LANGUAGES.map(lang => (
                <option key={lang.code} value={lang.code}>
                  {lang.icon} {lang.name}
                </option>
              ))}
            </select>
  
            <div className="btn-group btn-group-sm">
              <button
                onClick={startListening}
                disabled={isListening || !micPermission || !isTeamsClient || !speechServiceReady || buttonsDisabled}
                className={`btn ${isListening ? 'btn-secondary' : 'btn-primary'}`}
                style={{ fontSize: '0.8rem', padding: '4px 8px' }}
              >
                {isListening ? (
                  <>
                    <span className="spinner-grow spinner-grow-sm me-1" style={{ width: '0.5rem', height: '0.5rem' }}></span>
                    Listening
                  </>
                ) : (
                  <>
                    <i className="bi bi-mic-fill me-1"></i>
                    Listen
                  </>
                )}
              </button>
              <button
                onClick={stopListening}
                disabled={!isListening || buttonsDisabled}
                className="btn btn-danger"
                style={{ fontSize: '0.8rem', padding: '4px 8px' }}
              >
                <i className="bi bi-stop-fill"></i> Stop
              </button>
            </div>

            <button
              onClick={generateMeetingSummary}
              disabled={!meetingInfo || buttonsDisabled}
              className="btn btn-info w-100 mt-2"
              style={{ fontSize: '0.8rem', padding: '8px' }}
            >
              <i className="bi bi-file-text"></i> Rapor Create
            </button>
  
            {/* Kullanıcı Bilgisi */}
            {userInfo && (
              <div className="d-flex align-items-center bg-light rounded-pill px-2 py-1 mt-2">
                <div className="rounded-circle bg-primary text-white d-flex align-items-center justify-content-center me-1"
                  style={{ width: '24px', height: '24px', fontSize: '0.8rem' }}>
                  {userInfo.displayName || userName?.charAt(0)}
                </div>
                <div style={{ fontSize: '0.8rem' }}>
                  <div className="fw-bold">{userInfo.displayName || userName}</div>
                </div>
              </div>
            )}
          </div>
        </div>
      </nav>
  
      <div className="container px-2 py-3">
        {error && (
          <div className="alert alert-danger py-2 px-3 mb-3" role="alert" style={{ fontSize: '0.8rem' }}>
            <i className="bi bi-exclamation-triangle-fill me-2"></i>
            {error}
            {!micPermission && (
              <button onClick={checkMicrophonePermission} className="btn btn-sm btn-danger ms-2 py-0 px-2">
                Microphone Permission
              </button>
            )}
          </div>
        )}
  
        <div className="messages-container px-0"> {/* px-0 ekledik */}
        {displayedMessages.map((message, index) => {
          const colors = ['primary', 'success', 'info', 'warning', 'danger'];
          const colorClass = colors[index % colors.length];
          
          return (
            <div key={message.id} className={`card mb-1 border-${colorClass}`} style={{maxWidth: '100%'}}> {/* mb-1 yaptık, maxWidth 100% yaptık */}
              <div className={`card-header bg-${colorClass} bg-opacity-10 py-1 px-1`}> {/* padding'leri azalttık */}
                <div className="d-flex align-items-center">
                  <div className={`rounded-circle bg-${colorClass} text-white d-flex align-items-center justify-content-center me-2`}
                       style={{width: '24px', height: '24px', fontSize: '0.7rem'}}> {/* boyutu küçülttük */}
                    {message.userName.charAt(0)}
                  </div>
                  <div style={{fontSize: '0.7rem'}}>
                    <div className="fw-bold">{message.userName}</div>
                    <div className="text-muted" style={{fontSize: '0.6rem'}}>
                      {SUPPORTED_LANGUAGES.find(l => l.code === message.sourceLanguageId)?.icon}
                      {' '}Mesaj Saati :
                      {new Date(message.createdAt).toLocaleTimeString('tr-TR', {
                        hour: '2-digit',
                        minute: '2-digit'
                      })}
                    </div>
                  </div>
                </div>
              </div>
              <div className="card-body py-1 px-2"> {/* padding'leri azalttık */}
                {message.translations
                  .filter(t => t.languageId === selectedLanguage)
                  .map((translation, index) => (
                    <p key={index} className="mb-0" style={{fontSize: '0.9rem'}}>
                      {translation.text}
                    </p>
                  ))}
              </div>
            </div>
          );
        })}
  
          {displayedMessages.length === 0 && !isInitializing && (
            <div className="text-center py-4">
              <div className="text-muted mb-2" style={{ fontSize: '2rem' }}>
                <i className="bi bi-chat-dots"></i>
              </div>
              <h6 className="mb-1">There is no translated message yet</h6>
              <p className="text-muted mb-0" style={{ fontSize: '0.8rem' }}>
              The translated messages will appear here when the conversation starts
              </p>
            </div>
          )}

          {/* Footer - Fixed bottom */}
          <div className="fixed-bottom border-top" style={{
          backgroundColor: '#0078D4',  // Teams mavisi
          borderTop: '1px solid rgba(255, 255, 255, 0.1)',
          fontSize: '0.7rem',
          color: 'white'
          }}>
          {/* Copyright */}
          <div className="text-center py-1">
            <a href="https://www.cscvoice.ai" 
                target="_blank" 
                rel="noopener noreferrer" 
                className="text-white text-decoration-none">
              © 2024 CSC Voice AI - www.cscvoice.ai
            </a>
          </div>
          </div>

          {/* Ana content için bottom padding */}
          <div style={{paddingBottom: '48px'}}></div>
        </div>
      </div>
    </div>
  );
}

export default SpeechToTextComponent;